
import { defineComponent, PropType, ref, reactive } from 'vue'
import {
  ElDialog,
  ElButton,
  ElForm,
  ElFormItem,
  FormInstance,
  FormRules,
  ElNotification,
  ElColorPicker,
} from 'element-plus'

import { ICreateOrderStatusForm } from '@/types'
import { apiErrorNotification } from '@/core/helpers'
import { orderStatusesService } from '@/services'

export default defineComponent({
  name: 'CreateOrderStatusModal',

  components: {
    ElDialog,
    ElButton,
    ElForm,
    ElFormItem,
    ElColorPicker,
  },

  props: {
    modelValue: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive<ICreateOrderStatusForm>({
      title: '',
      color: '',
    })

    const predefineColors = ref([
      '#80d742',
      '#1e73be',
      '#a01397',
      '#0074a2',
      '#dd9932',
      '#de3333',
    ])

    const formValidationRules = reactive<FormRules>({
      title: [
        {
          required: true,
          message: 'Title is required!',
          trigger: 'change',
        },
        {
          min: 1,
          max: 255,
          message: 'Title length should be 1 to 255',
          trigger: 'change',
        },
      ],
      color: [
        {
          required: true,
          message: 'Color is required!',
          trigger: 'change',
        },
      ],
    })

    const onOpen = () => {
      formValues.title = ''
      formValues.color = ''

      formRef.value?.resetFields()
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await orderStatusesService.create(formValues)

        ElNotification({
          message: 'Order status successfully created!',
          type: 'success',
        })

        emit('close', true)
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onClose = () => {
      if (!loading.value) {
        emit('close')
      }
    }

    return {
      onClose,
      onSubmit,
      onOpen,
      loading,
      formRef,
      formValues,
      formValidationRules,
      predefineColors,
    }
  },
})
