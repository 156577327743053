
import { defineComponent, ref, onMounted, computed, h } from 'vue'
import {
  ElButton,
  ElIcon,
  ElInput,
  ElNotification,
  ElMessageBox,
} from 'element-plus'
import { CirclePlus, Search, Edit } from '@element-plus/icons-vue'
import { useDebounceFn } from '@vueuse/core'
import draggable from 'vuedraggable'

import { apiErrorNotification } from '@/core/helpers'
import { localeDate, localeNumber } from '@/core/utils'
import { useOrderStatuses, usePermissions } from '@/core/compositions'
import {
  EPermissions,
  IOrderStatus,
  ITableFilterOption,
  ITableFilterValueOption,
  TIndexedObject,
} from '@/types'
import { orderStatusesService } from '@/services'

import AppTableFilters from '@/components/stateless/AppTableFilters/AppTableFilters.vue'

import CreateModal from './components/CreateModal.vue'
import UpdateModal from './components/UpdateModal.vue'

export default defineComponent({
  name: 'OrderStatusesView',

  components: {
    ElButton,
    ElIcon,
    CirclePlus,
    ElInput,

    draggable,

    AppTableFilters,
    CreateModal,
    UpdateModal,
  },

  setup() {
    const { hasPermission } = usePermissions()

    const { getOrderstatusesPagination, orderStatuses } = useOrderStatuses()

    const loading = ref(true)
    const searchValue = ref(null)
    const filters = ref<ITableFilterValueOption[]>([])

    const createOrderStatusModalVisible = ref(false)
    const updateOrderStatusModalVisible = ref<IOrderStatus | null>(null)

    const filterOptions: ITableFilterOption[] = [
      {
        label: 'Archived',
        key: 'isArchived',
        type: 'boolean',
      },
    ]

    const filtersObject = computed(() => {
      const obj: TIndexedObject = {}

      filters.value.forEach((filter) => {
        if (filter.value !== null) {
          obj[filter.key] = filter.value
        }
      })

      return obj
    })

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      loading.value = true

      try {
        await getOrderstatusesPagination({
          ...(searchValue.value
            ? { search: searchValue.value as unknown as string }
            : null),
          ...filtersObject.value,
        })
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onSearchChange = useDebounceFn(() => {
      getData()
    }, 700)

    const onFilterChanged = useDebounceFn(() => {
      getData()
    }, 700)

    const openCreateOrderStatusModal = () => {
      createOrderStatusModalVisible.value = true
    }
    const closeCreateOrderStatusModal = (refreshData: boolean) => {
      createOrderStatusModalVisible.value = false

      if (refreshData) {
        getData()
      }
    }

    const openUpdateOrderStatusModal = (orderStatus: IOrderStatus) => {
      updateOrderStatusModalVisible.value = orderStatus
    }
    const closeUpdateOrderStatusModal = (refreshData: boolean) => {
      updateOrderStatusModalVisible.value = null

      if (refreshData) {
        getData()
      }
    }

    const onSubmitDelete = async (orderStatus: IOrderStatus) => {
      const confirm = await ElMessageBox.confirm('Warning', {
        message: h('span', null, [
          'Are you sure you want to delete ',
          h('span', { class: 'font-bold' }, `${orderStatus.title}?`),
        ]),
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })

      if (!confirm) {
        return false
      }

      loading.value = true

      try {
        await orderStatusesService.delete(orderStatus.id)

        ElNotification({
          message: 'Order status successfully delete!',
          type: 'success',
        })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onSortChange = async () => {
      loading.value = true

      try {
        const listOrder = orderStatuses.value.map((item, index) => {
          return {
            id: item.id,
            order: index + 1,
          }
        })

        await orderStatusesService.updateListOrder({ order: listOrder })

        ElNotification({
          message: 'List successfully update!',
          type: 'success',
        })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    return {
      localeDate,
      localeNumber,
      loading,
      orderStatuses,
      Search,
      searchValue,
      onSearchChange,
      filters,
      filterOptions,
      onFilterChanged,
      hasPermission,
      EPermissions,
      openCreateOrderStatusModal,
      closeCreateOrderStatusModal,
      createOrderStatusModalVisible,
      Edit,
      openUpdateOrderStatusModal,
      closeUpdateOrderStatusModal,
      updateOrderStatusModalVisible,
      onSubmitDelete,
      onSortChange,
    }
  },
})
